import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ShareAccident from "../views/ShareAccident.vue";
import BestPractices from "../views/BestPractices.vue";
import Community from "../views/Community.vue";
import Referential from "../views/Referential.vue";
import Reporting from "../views/Reporting.vue";
import Iso45001 from "../views/Iso45001.vue";
import SafePlace from "../views/SafePlace.vue";
import Statistics from "../views/Statistics.vue";
import Plan from "../views/Plan.vue";
import Content from "../views/Content.vue";
import Agenda from "../views/Agenda.vue";
import DefaultLayout from "../components/Layout/DefaultLayout.vue";
import store from "../store";
import configuration from "../config";

const routes = [
    {
        path: "/",
        component: DefaultLayout,
        children: [
            {
                path: "/",
                name: "Home",
                component: Home,
            },
            {
                path: "/share-accidents",
                name: "ShareAccident",
                component: ShareAccident,
            },
            {
                path: "/share-accidents/:id",
                name: "ShareAccidentWithId",
                component: ShareAccident,
            },
            {
                path: "/best-practices",
                name: "BestPractices",
                component: BestPractices,
            },
            {
                path: "/best-practices/:id",
                name: "BestPracticesWithId",
                component: BestPractices,
            },
            {
                path: "/reporting",
                name: "Reporting",
                component: Reporting,
            },
            {
                path: "/iso45001",
                name: "Iso45001",
                component: Iso45001,
            },
            {
                path: "/statistics",
                name: "Statistics",
                component: Statistics,
            },
            {
                path: "/content",
                name: "Content",
                component: Content,
            },
        ],
    },
    {
        path: "/",
        component: DefaultLayout,
        props: { showWaves: false, backgroundWorld: true },
        children: [
            {
                path: "/community",
                name: "Community",
                component: Community,
            },
        ],
    },
    {
        path: "/",
        name: "Contents",
        component: DefaultLayout,
        children: [
            {
                path: "/referential",
                name: "Referential",
                component: Referential,
            },
            {
                path: "/safe-place",
                name: "SafePlace",
                component: SafePlace,
            },
            {
                path: "/plan",
                name: "Plan",
                component: Plan,
            },
            {
                path: "/agenda",
                name: "Agenda",
                component: Agenda,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(() => {
    const redirect = localStorage.getItem("redirect");
    if (redirect) {
        localStorage.removeItem("redirect");
        return redirect;
    }
});

router.beforeEach((to, from, next) => {
    if (store.state.user.idLdap === undefined) {
        sessionStorage.setItem("starting", "start");
        store
            .dispatch("getUserFromApi")
            .then(() => {
                sessionStorage.removeItem("starting");
                next();
            })
            .catch(() => {
                localStorage.setItem("redirect", to.fullPath);
                window.location.replace(`${configuration.api_auth_url}login`);
                sessionStorage.removeItem("starting");
            });
    } else {
        next();
    }
});

export default router;
